import { useQuery } from '@tanstack/react-query';
import { Card, Form, Input, Popconfirm, Space, Table, message } from 'antd';
import dayjs, { extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { compact, isNil } from 'lodash-es';
import Nzh from 'nzh';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { honoClient } from './honoClient';
import ExcelJS from 'exceljs';
import { saveAs } from './component/saveAs';
import { useGlobalManagementStore } from '../apartment/myInfo/useGlobalManagement';
import templateUrl from './delivery-template.xlsx?url';
import Compact from 'antd/es/space/Compact';

extend(isBetween);
export default function Transaction() {
	const { globalManagement, setGlobalManagement } = useGlobalManagementStore();
	const [form] = Form.useForm();
	const orderId = Form.useWatch('orderId', form);
	const clientName = Form.useWatch('client', form);
	const stock = Form.useWatch('stock', form);
	const navigate = useNavigate();

	const [currentPage, setCurrentPage] = useState(1);
	const [uuid, setUuid] = useState();
	const query = useQuery({
		queryKey: ['transaction', currentPage, orderId, clientName],
		queryFn: async () => {
			const res = await honoClient.transaction.$post({
				json: {
					page: currentPage,
					orderId,
					client: clientName,
					offsetLimit: 5,
				},
			});
			return await res.json();
		},
	});

	const detailQuery = useQuery({
		queryKey: ['transactionDetail', uuid],
		queryFn: async () => {
			const res = await honoClient.transactionDetail.$post({
				json: { uuid: uuid ?? '' },
			});
			return await res.json();
		},
		enabled: !isNil(uuid),
	});

	const transactionDetail = detailQuery?.data;

	return (
		<div className="flex flex-col">
			{/* grid grid-cols-1 gap-2 md:grid-cols-4 */}
			<div className="p-3 bg-white">
				<Form
					form={form}
					// onValuesChange={(v) => {
					// 	console.log(v);
					// }}
				>
					<div className="grid grid-cols-2 gap-2">
						<Form.Item label="订单号" name="orderId">
							<Input
								onChange={() => {
									setCurrentPage(1);
								}}
							/>
						</Form.Item>
						<Form.Item label="客户" name={'client'}>
							<Input
								onChange={() => {
									setCurrentPage(1);
								}}
							/>
						</Form.Item>
					</div>
				</Form>

				<Table
					size="small"
					scroll={{ x: 600 }}
					pagination={{
						showQuickJumper: true,
						total: query?.data?.total ?? 0,
						showTotal: (totals) => `共${totals}条`,
						pageSize: 5,
						onChange: (page) => {
							setCurrentPage(page);
						},
						showSizeChanger: false,
					}}
					rowKey={'orderId'}
					dataSource={query?.data?.data ?? []}
					columns={[
						{
							title: '订单号',
							dataIndex: 'orderId',
						},
						{
							title: '客户',
							dataIndex: 'client',
						},
						{
							title: '日期',
							dataIndex: 'createdAt',
							render: (v) => dayjs(v).format('YYYY-MM-DD HH:mm'),
						},
						// {
						// 	title: '类型',
						// 	dataIndex: 'type',
						// },
						{
							fixed: 'right',
							title: '操作',
							key: 'action',
							render: (_, record) => (
								<div className="flex flex-row flex-wrap gap-2">
									<a
										// biome-ignore lint/a11y/useValidAnchor: <explanation>
										onClick={() => {
											setUuid(record.uuid);
										}}
									>
										查看
									</a>
									<a
										// biome-ignore lint/a11y/useValidAnchor: <explanation>
										onClick={async () => {
											const res = await honoClient.transactionDetail.$post({
												json: { uuid: record.uuid ?? '' },
											});
											const theRecord = await res.json();

											const template = await (await fetch(templateUrl)).blob();

											const workbook = new ExcelJS.Workbook();
											const excelFile = await workbook.xlsx.load(template);

											const worksheet = excelFile.getWorksheet(1);
											if (isNil(worksheet)) {
												message.error('worksheet is nil');
												return;
											}

											const baseRow = 7;
											if (isNil(worksheet)) {
												message.error('invalid excel');
												return;
											}

											// 填写 no
											worksheet.getCell('F2').value = theRecord?.orderId;
											worksheet.getCell('F5').value = dayjs(
												(theRecord?.orderId as string).substring(0, 8),
												'YYYYMMDD',
											).format('YYYY年MM月DD日');
											// 填写 客户
											worksheet.getCell('B5').value = theRecord?.client;

											// 填写详细规格
											const commodity = theRecord?.commodity;
											const stockRows = theRecord?.stockRows;

											if (!isNil(commodity)) {
												for (
													let commodityIndex = 0;
													commodityIndex < commodity.length;
													commodityIndex++
												) {
													const mCommodity = commodity[commodityIndex];

													const mRow = worksheet.getRow(
														baseRow + commodityIndex * 2,
													);

													mRow.getCell(1).value = mCommodity.brand;
													mRow.getCell(2).value = mCommodity.specifications;
													mRow.getCell(3).value = mCommodity.count;

													mRow.getCell(4).value =
														mCommodity?.totalCubic ?? mCommodity?.cubic;

													mRow.getCell(5).value = mCommodity.unitPrice;
													mRow.getCell(6).value = mCommodity.totalPrice;
													const nRow = worksheet.getRow(
														baseRow + commodityIndex * 2 + 1,
													);

													nRow.getCell(1).value = mCommodity.brSet.join(' ');
												}
											}

											if (isNil(commodity) && stockRows) {
												for (
													let stockRowsIndex = 0;
													stockRowsIndex < stockRows.length;
													stockRowsIndex++
												) {
													const mStockRows = stockRows[stockRowsIndex];
													const mRow = worksheet.getRow(
														baseRow + stockRowsIndex * 2,
													);

													mRow.getCell(1).value = mStockRows.brand;
													mRow.getCell(2).value = mStockRows.specifications;
													mRow.getCell(3).value = mStockRows.count;
													mRow.getCell(4).value =
														mStockRows?.totalCubic ?? mStockRows?.cubic;
													mRow.getCell(5).value = mStockRows.unitPrice;
													mRow.getCell(6).value = mStockRows.totalPrice;
													const nRow = worksheet.getRow(
														baseRow + stockRowsIndex * 2 + 1,
													);

													nRow.getCell(1).value = mStockRows.brSet.join(' ');
												}
											}

											// 填写合计数量
											worksheet.getCell('C17').value = theRecord?.stockNumber;
											worksheet.getCell('D17').value = theRecord?.totalCubic;
											// 填写合计金额
											worksheet.getCell('F17').value = theRecord?.totalPrice;
											// 填写大写金额
											worksheet.getCell('B18').value = Nzh.cn.encodeB(
												Number.parseFloat(theRecord.totalPrice),
												{},
											);

											worksheet.getCell('C17').value = {
												formula: '=SUM(C7,C9,C11,C13,C15)',
											};
											worksheet.getCell('D17').value = {
												formula: '=SUM(D7,D9,D11,D13,D15)',
											};
											worksheet.getCell('F17').value = {
												formula: '=SUM(F7,F9,F11,F13,F15)',
											};
											worksheet.getCell('F18').value = {
												formula: '=SUM(F7,F9,F11,F13,F15)',
											};
											worksheet.getCell('B18').value = {
												formula:
													'=SUBSTITUTE(SUBSTITUTE(TEXT(INT(F17),"[DBNum2][$-804]G/通用格式元"&IF(INT(F17)=F17,"整",""))&TEXT(MID(F17,FIND(".",F17&".0")+1,1),"[DBNum2][$-804]G/通用格式角")&TEXT(MID(F17,FIND(".",F17&".0")+2,1),"[DBNum2][$-804]G/通用格式分"),"零角","零"),"零分","")',
											};

											// 填写小写金额
											worksheet.getCell('F18').value = theRecord?.totalPrice;

											workbook.xlsx.writeBuffer().then((data) => {
												const blob = new Blob([data], {
													type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
												});

												saveAs(blob, `${theRecord.orderId}-delivery.xlsx`);
											});
										}}
									>
										下载
									</a>

									<a
										// biome-ignore lint/a11y/useValidAnchor: <explanation>
										onClick={async () => {
											navigate(
												`/wood/PartReturnedPurchase?uuid=${record.uuid}`,
											);
										}}
									>
										退货
									</a>

									<a
										// biome-ignore lint/a11y/useValidAnchor: <explanation>
										onClick={async () => {
											navigate(`/wood/transactionEdit?uuid=${record.uuid}`);
										}}
									>
										编辑
									</a>
									{/* <a>删除</a>
									<a>退货</a>
									 */}
								</div>
							),
						},
					]}
				/>
			</div>
			{transactionDetail && (
				<div className="pb-6 p-3  bg-white">
					<table className="table-auto">
						<tbody>
							<tr>
								<td>日期</td>
								<td>
									{dayjs(transactionDetail.createdAt).format(
										'YYYY-MM-DD HH:mm',
									)}
								</td>
							</tr>

							<tr>
								<td>No.</td>
								<td>{transactionDetail.orderId}</td>

								<td>客户</td>
								<td>{transactionDetail.client}</td>
							</tr>

							<tr>
								<td>品牌</td>
								<td>规格</td>
								<td>件数</td>

								<td>数量（m³）</td>
								<td>单价（元）</td>
								{/* 
          <td >总成本</td>
    
          <td >利润</td> */}

								<td>总价</td>
							</tr>

							{transactionDetail?.commodity?.map((item) => {
								return (
									<tr key={item.key}>
										<td>{item.brand}</td>
										<td>{item.specifications}</td>
										<td>{item.count}</td>
										<td>{item.totalCubic}</td>
										<td>{item.unitPrice}</td>

										<td>{item.totalPrice}</td>
									</tr>
								);
							})}

							{isNil(transactionDetail?.commodity) &&
								transactionDetail?.stockRows &&
								transactionDetail?.stockRows?.map((item) => {
									return (
										<tr key={item.key}>
											<td>{item.brand}</td>
											<td>{item.specifications}</td>
											<td>{item.count}</td>
											<td>{item.totalCubic}</td>
											<td>{item.unitPrice}</td>

											<td>{item.totalPrice}</td>
										</tr>
									);
								})}

							<tr>
								<td>总立方数</td>
								<td>{transactionDetail.totalCubic}</td>

								<td>总价</td>
								<td>{transactionDetail.totalPrice}</td>
								{/* 
          <td *ngIf="woodService.isSuperAdmin()">总利润</td>
          <td *ngIf="woodService.isSuperAdmin()">
            { transactionDetail.totalProfit }
          </td> */}
							</tr>

							<tr>
								<td>合计数量</td>
								<td>
									{transactionDetail?.stockRows?.reduce(
										(p, c) => (c?.count ?? 0) + p,
										0,
									)}
								</td>

								<td>大写</td>
								{transactionDetail.totalPrice && (
									<td>
										{Nzh.cn.encodeB(
											Number.parseFloat(transactionDetail.totalPrice),
											{},
										)}
									</td>
								)}
							</tr>

							<tr>
								<td>状态</td>
								<td>{transactionDetail.status}</td>
							</tr>

							<tr>
								<td>出库明细</td>
								<td />
							</tr>
						</tbody>
					</table>

					<Table
						scroll={{ x: 1200 }}
						size="small"
						columns={compact([
							{ title: 'brand', dataIndex: 'brand' },
							{
								title: 'specifications',
								dataIndex: 'specifications',
							},
							{ title: 'ack', dataIndex: 'ack', width: 150 },
							{ title: 'br#', dataIndex: 'br#' },
							{ title: 'lengths', dataIndex: 'lengths', width: 100 },
							{ title: 'pcs', dataIndex: 'pcs' },
							{ title: 'bf', dataIndex: 'bf' },
							{ title: 'cubic', dataIndex: 'cubic' },
							globalManagement && { title: 'cost', dataIndex: 'cost' },
							{ title: 'unitPrice', dataIndex: 'unitPrice' },
							globalManagement && {
								title: 'totalCost',
								dataIndex: 'totalCost',
							},
							{ title: 'totalPrice', dataIndex: 'totalPrice' },
						])}
						dataSource={transactionDetail?.stock}
						pagination={false}
						rowKey={'uuid'}
					/>

					<div className="flex flex-row flex-wrap gap-2 my-2">
						<div>总立方数</div>
						<div>{transactionDetail.totalCubic}</div>

						{globalManagement && (
							<>
								<div>成本总计</div>
								<div>{transactionDetail.totalCost}</div>
							</>
						)}
						<div>总价</div>
						<div>{transactionDetail.totalPrice}</div>

						{globalManagement && (
							<>
								<div>应利润</div>
								<div>{transactionDetail.totalProfit}</div>
							</>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
